html,
body {
  background: white;
  color: #121c2b;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  width: 100%;
}

html * {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: Azo Sans, Helvetica Neue, Helvetica, Roboto, sans-serif;
}

@media screen and (max-width: 1080px) {
  body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
  }

  body::-webkit-scrollbar {
    display: none;
  }
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

pre,
code {
  font-family: Consolas, Liberation Mono, Menlo, Courier, monospace;
}

header,
nav,
main,
article,
section,
aside,
footer {
  display: block;
}

a {
  background-color: transparent;
}

input[type='search'] {
  -moz-appearance: none; /* older firefox */
  -webkit-appearance: none; /* safari, chrome, edge and ie mobile */
  appearance: none; /* rest */
}

svg[data-sly-svgs] {
  display: none;
}
