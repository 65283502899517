/**
 * WHAT IS THIS FILE?
 *
 * Globally applied styles. No matter which components are in the page or matching route,
 * the styles in here will be applied to the Document, without any sort of CSS scoping.
 *
 */
@import './reset';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  --tw-color-primary-50: 240 249 255;
  --tw-color-primary-100: 224 242 254;
  --tw-color-primary-200: 186 230 253;
  --tw-color-primary-300: 125 211 252;
  --tw-color-primary-400: 56 189 248;
  --tw-color-primary-500: 14 165 233;
  --tw-color-primary-600: 2 132 199;
  --tw-color-primary-700: 3 105 161;
  --tw-color-primary-800: 7 89 133;
  --tw-color-primary-900: 12 74 110;
  --color-primary-50: rgb(var(--tw-color-primary-50)); /* #f0f9ff */
  --color-primary-100: rgb(var(--tw-color-primary-100)); /* #e0f2fe */
  --color-primary-200: rgb(var(--tw-color-primary-200)); /* #bae6fd */
  --color-primary-300: rgb(var(--tw-color-primary-300)); /* #7dd3fc */
  --color-primary-400: rgb(var(--tw-color-primary-400)); /* #38bdf8 */
  --color-primary-500: rgb(var(--tw-color-primary-500)); /* #0ea5e9 */
  --color-primary-600: rgb(var(--tw-color-primary-600)); /* #0284c7 */
  --color-primary-700: rgb(var(--tw-color-primary-700)); /* #0369a1 */
  --color-primary-800: rgb(var(--tw-color-primary-800)); /* #075985 */
  --color-primary-900: rgb(var(--tw-color-primary-900)); /* #0c4a6e */
  /* #endregion  /**======== Primary Color =========== */

  /* Rotations */
  --rotation-0: 0deg;
  --rotation-90: 90deg;
  --rotation-180: 180deg;
  --rotation-270: 270deg;

  --col1: calc(((100vw + 1rem) * (1 / 4)) - 1rem);
  --col2: calc(((100vw + 1rem) * (2 / 4)) - 1rem);
  --col3: calc(((100vw + 1rem) * (3 / 4)) - 1rem);
  --col4: 100vw;

  @media screen(md) {
    --col1: 4rem;
    --col2: 9.5rem;
    --col3: 15rem;
    --col4: 20.5rem;
    --col5: 26rem;
    --col6: 31.5rem;
    --col7: 37rem;
    --col8: 42.5rem;
    --col9: 48rem;
    --col10: 53.5rem;
    --col11: 59rem;
    --col12: 64.5rem;
  }
}

@layer base {
  svg {
    display: inline-block;
  }

  /* #region  /**=========== Typography =========== */
  /* YOU SHOULD NOT CHANGE OR ADD ANY FONTS UNLESS EXPLICITLY APPROVED BY JARED. IF YOU MAKE A CHANGE THAT IS NOT APPROVED YOU WILL BE TRACKED DOWN THROUGH GIT HISTORY AND SHAMED. IF A FONT IN THE DESIGN DOES NOT MATCH ONE OF THESE FONTS TELL JARED SO HE CAN USE A FONT IN OUR SYSTEM. */
  /* inter var - latin */
  /* Tiempos */
  .font-t-xxl {
    font: 500 40px/48px Tiempos Headline, Georgia, serif;
  }
  .font-t-xl {
    font: 500 32px/44px Tiempos Headline, Georgia, serif;
  }
  .font-t-l {
    font: 500 24px/32px Tiempos Headline, Georgia, serif;
  }
  .font-t-m {
    font: 500 20px/28px Tiempos Headline, Georgia, serif;
  }
  .font-t-s {
    font: 500 18px/28px Tiempos Headline, Georgia, serif;
  }
  .font-t-xs {
    font: 500 16px/24px Tiempos Headline, Georgia, serif;
  }

  @media screen(md) {
    .font-t-xxl {
      font: 500 48px/60px Tiempos Headline, Georgia, serif;
    }
    .font-t-xl {
      font: 500 40px/48px Tiempos Headline, Georgia, serif;
    }
    .font-t-l {
      font: 500 32px/44px Tiempos Headline, Georgia, serif;
    }
    .font-t-m {
      font: 500 24px/32px Tiempos Headline, Georgia, serif;
    }
  }

  /* Azo */
  .font-t-m-azo {
    font: 500 24px/36px Azo Sans, Roboto, sans-serif;
  }
  .font-t-s-azo {
    font: 500 18px/28px Azo Sans, Roboto, sans-serif;
  }
  .font-t-xs-azo {
    font: 500 16px/24px Azo Sans, Roboto, sans-serif;
  }
  .font-t-xxs-azo {
    font: 500 14px/20px Azo Sans, Roboto, sans-serif;
  }
  .font-t-xxxs-azo {
    font: 500 12px/16px Azo Sans, Roboto, sans-serif;
  }

  .font-b-xl {
    font: 400 24px/36px Azo Sans, Roboto, sans-serif;
  }

  .font-b-l {
    font: 400 18px/28px Azo Sans, Roboto, sans-serif;
  }
  .font-b-m {
    font: 400 16px/24px Azo Sans, Roboto, sans-serif;
  }
  .font-b-s {
    font: 400 14px/20px Azo Sans, Roboto, sans-serif;
  }
  .font-b-xs {
    font: 400 12px/16px Azo Sans, Roboto, sans-serif;
  }

  .font-l-m {
    font: 700 12px/16px Azo Sans, Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  /* YOU SHOULD NOT CHANGE OR ADD ANY FONTS UNLESS EXPLICITLY APPROVED BY JARED. IF YOU MAKE A CHANGE THAT IS NOT APPROVED YOU WILL BE TRACKED DOWN THROUGH GIT HISTORY AND SHAMED. IF A FONT IN THE DESIGN DOES NOT MATCH ONE OF THESE FONTS TELL JARED SO HE CAN USE A FONT IN OUR SYSTEM. */
  /* #endregion  /**======== Typography =========== */

  .cursor-newtab {
    cursor: url('/images/new-tab.png') 10 10, pointer;
  }

  .layout {
    /* 1100px */
    max-width: min(68.75rem, calc(100% - 2rem));
    @apply mx-auto w-11/12 md:w-[42.5rem] lg:w-[64.5rem];
  }

  .wrapper {
    @apply lg:mx-auto lg:w-[1080px];
  }

  .bg-dark a.custom-link {
    @apply border-gray-200 hover:border-gray-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }

  .children-nowrap > * {
    @apply whitespace-nowrap;
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#1a7473, #1a7473),
      linear-gradient(to right, #488f8f, #76acaa);
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }
  /* Chrome; Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .rounded-box {
    @apply border-slate-lighter-90 rounded-md border;
  }

  .reset-all {
    all: initial;
  }

  .border-spacing-0 {
    border-spacing: 0;
  }
}

@layer components {
  .rw-launcher {
    @include animation(0, 0.5s, slide-in);

    &:focus {
      outline: none;
    }

    &.rw-hide {
      display: none;
    }

    &.rw-full-screen {
      @include launcher-fs;
    }

    .rw-tooltip-body {
      @apply bg-viridian-lighter-90 p-4;

      box-sizing: border-box;
      border-radius: 8px;
      position: absolute;
      right: 78px;
      bottom: 12px;
      box-shadow: 0 0.9px 1.4px -4px rgba(0, 0, 0, 0.042),
        0 2.3px 3.3px -4px rgba(0, 0, 0, 0.061),
        0 4.7px 6.3px -4px rgba(0, 0, 0, 0.075),
        0 9px 11.2px -4px rgba(0, 0, 0, 0.089),
        0 18.2px 20.9px -4px rgba(0, 0, 0, 0.108),
        0 50px 50px -4px rgba(0, 0, 0, 0.15);

      width: 320px;
      font-size: 16px;
      line-height: 19px;
      font-family: $fontfamily;
      animation: slideUp 0.3s ease-in-out;
      transition: all 0.2s ease-in-out;

      @media screen and (max-width: 800px) {
        right: 74px;
        width: calc(100vw - 90px);
      }

      .rw-reply {
        @apply border-viridian-base hover:bg-viridian-lighter-80 border bg-white;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0.9px 2.1px -4px rgba(0, 0, 0, 0.056),
          0 2.3px 5.1px -4px rgba(0, 0, 0, 0.081),
          0 4.7px 9.6px -4px rgba(0, 0, 0, 0.1),
          0 9px 17.2px -4px rgba(0, 0, 0, 0.119),
          0 18.2px 32.2px -4px rgba(0, 0, 0, 0.144),
          0 50px 77px -4px rgba(0, 0, 0, 0.2);

        cursor: pointer;
      }

      &[data-popper-placement='right'] {
        margin-left: 20px;
        .rw-tooltip-decoration {
          left: -6px;
          clip-path: polygon(0% 50%, 50% 0, 50% 100%);
          -webkit-clip-path: polygon(0% 50%, 50% 0, 50% 100%);
        }
      }
      &[data-popper-placement='left'] {
        margin-right: 20px;
        .rw-tooltip-decoration {
          right: -6px;
          clip-path: polygon(100% 50%, 50% 0, 50% 100%);
          -webkit-clip-path: polygon(100% 50%, 50% 0, 50% 100%);
        }
      }

      &[data-popper-placement='bottom'] {
        margin-top: 20px;
        .rw-tooltip-decoration {
          top: -6px;
          clip-path: polygon(100% 50%, 0 50%, 50% 0);
          -webkit-clip-path: polygon(100% 50%, 0 50%, 50% 0);
        }
      }

      &[data-popper-placement='top'] {
        margin-bottom: 20px;
        .rw-tooltip-decoration {
          bottom: -6px;
          clip-path: polygon(100% 50%, 0 50%, 50% 100%);
          -webkit-clip-path: polygon(100% 50%, 0 50%, 50% 100%);
        }
      }

      .rw-image-details {
        object-fit: scale-down;
        max-width: 100%;
        margin-top: 8px;
        margin-bottom: 2px;
      }
      .rw-replies {
        @include replies;
        justify-content: flex-end;
        margin-left: 4px;
      }

      .rw-separator {
        width: calc(100% + 16px);
        position: relative;
        left: -8px;
        height: 1px;
        background-color: #e0e0e0;
      }

      .rw-reply {
        @include reply;
      }

      .rw-response {
        text-align: left;
        margin-left: 10px;
        margin-right: 10px;
        ul {
          margin-top: 1em;
          margin-bottom: 1em;
          padding-left: 40px;
        }
      }
      .rw-image-frame {
        object-position: 0 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 9px;
      }
    }
    .rw-tooltip-decoration {
      @apply bg-viridian-lighter-90;
      position: absolute;
      bottom: 12px;
      right: -6px;
      height: 12px;
      width: 12px;
      transition: all 0.2s ease-in-out;
      clip-path: polygon(100% 50%, 50% 0, 50% 100%);
      -webkit-clip-path: polygon(100% 50%, 50% 0, 50% 100%);
    }

    .slick-dots li {
      width: 9px;
    }

    .slick-arrow {
      width: 15px;
      &.slick-next {
        right: -17px;
        top: initial;
        bottom: 10px;
        &:before {
          color: #c0c0c0;
        }
      }
      &.slick-prev {
        left: -19px;
        top: initial;
        bottom: 10px;
        &:before {
          color: #c0c0c0;
        }
      }
    }

    .rw-slider-safe-zone {
      width: 87%;
      position: relative;
      margin-bottom: 32px;
      left: 18px;
      .slick-list {
        .rw-tooltip-response {
          max-height: min(60vh, 350px);
          overflow-y: auto;
        }
      }
    }

    .rw-tooltip-response {
      margin-top: 1.5rem;
      outline: none;
    }

    .rw-tooltip-close {
      position: absolute;
      right: 7px;
      top: 3px;
      color: red;
      height: 15px;
      width: 15px;
      transition: all 0.2s ease-in-out;
      button {
        @apply bg-slate-base;
      }
    }
  }

  .rw-group-message.rw-from-client {
    span.rw-message-date {
      text-align: right;
      flex-basis: 100%;
      margin-left: 0;
      margin-right: 4px;
      margin-top: 5px;
      font-size: 11px;
      color: #a4a7ab;
    }
  }

  .rw-group-message.rw-from-response {
    span.rw-message-date {
      text-align: left;
      flex-basis: 100%;
      margin-left: 7px;
      margin-top: 5px;
      font-size: 11px;
      color: #a4a7ab;
    }

    .rw-message:first-child:not(:only-child) {
      .rw-response {
        border-radius: 0 15px 15px 0;
      }
      margin-bottom: 2px;
    }

    .rw-message:not(:first-child):not(:last-child):not(:last-child) {
      .rw-response {
        border-radius: 0 15px 15px 0;
      }
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .rw-message:not(:first-child):not(:only-child) {
      .rw-avatar {
        display: none;
      }
      &.rw-with-avatar {
        margin-left: 33px;
      }
    }

    .rw-message:last-child:not(:only-child) {
      margin-top: 2px;
    }
  }

  .rw-message.rw-typing-indication {
    .rw-response {
      box-sizing: content-box;
      height: 16px;
    }
  }

  .rw-widget-embedded {
    .rw-messages-container {
      height: 100%;
      max-height: 100%;
    }
  }

  .rw-full-screen {
    .rw-messages-container {
      @include messages-container-fs;
    }
  }

  @media screen and (max-width: 800px) {
    .rw-messages-container {
      @include messages-container-fs;
    }
  }

  div#wave {
    position: relative;
    text-align: center;
    width: 25px;
    height: 13px;
    margin-left: auto;
    margin-right: auto;
    .rw-dot {
      @apply bg-slate-base opacity-75;
      display: inline-block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      margin-right: 3px;

      animation: wave 1.6s linear infinite;

      &:nth-child(2) {
        animation-delay: -1.4s;
      }

      &:nth-child(3) {
        animation-delay: -1.2s;
      }
    }
  }

  @keyframes wave {
    0%,
    60%,
    100% {
      transform: initial;
    }

    30% {
      transform: translateY(-5px);
    }
  }

  .rw-conversation-container {
    .rw-replies {
      @include replies;
    }

    .rw-reply {
      @include reply;
    }

    .rw-response {
      @include message-bubble($grey-2, #000, 0 15px 15px 15px);
      max-width: 85%;
    }

    .rw-avatar {
      @apply relative bottom-1 mr-1 h-8 w-8 rounded-full;
    }
  }

  .rw-avatar {
    @apply bg-viridian-lighter-95 text-viridian-base relative flex h-14 w-14 items-center justify-center rounded-[50%] shadow-sm focus:outline-none;
  }

  .rw-conversation-container {
    .rw-message {
      @apply font-b-s animate-fadeIn relative flex flex-wrap items-end px-3 pb-2 leading-5;
      .rw-markdown {
        p {
          margin: 0;
        }
        img {
          max-width: 100%;
        }
      }
    }

    .rw-client {
      @include message-bubble($blue-1, $white);
      background-color: $tertiary;
      max-width: 85%;
      margin-left: auto;
      overflow-wrap: break-word;
      a {
        color: $turqois-1;
      }
    }

    .rw-response {
      @include message-bubble($grey-2, #000, 0 15px 15px 15px);
      overflow-wrap: break-word;
    }

    /* For markdown elements created with default styles */
    .rw-message-text {
      @apply relative inline-block rounded-xl py-2 px-3;
      margin: 0;
      overflow-wrap: anywhere;
      .rw-markdown {
        ol {
          list-style-type: decimal;
          list-style-position: inside;
        }
        ul {
          list-style-type: disc;
          list-style-position: inside;
        }
        p {
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .gallery {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-rows: 250px 150px;
    grid-auto-flow: dense;
  }

  .gallery .item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 480px) {
    .gallery .item:first-child {
      grid-area: 1 / 1 / span 2 / span 2;
    }

    .gallery .item:nth-child(3n) {
      grid-column: span 2;
    }
  }

  .profile-review-section {
    > div {
      @apply mr-m border-slate-lighter-90 p-m min-w-[75vw] snap-center rounded-md border;
      @apply md:mr-0 md:min-w-0 md:flex-col md:border-none md:p-0;
    }

    > div:last-of-type {
      @apply mr-0;
    }

    .reviewInfo {
      @apply font-b-s md:font-b-m;
    }
  }

  .image-gallery .gallery__item:nth-child(n + 4) {
    @apply md:hidden;
  }

  .carousel {
    > * {
      @apply snap-center snap-always;
    }
  }

  .favorited {
    --outline-color: white;
  }

  .form-field {
    > input[type='checkbox'],
    > input[type='radio'] {
      margin-right: 1rem;
    }
    .react-datepicker__input-container {
      display: block;
    }
  }

  .searchPageGrid {
    @apply flex flex-col lg:grid;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 'minmax(0,820px) minmax(38vw,1fr)';
    grid-template:
      'heading map'
      'filters map'
      'noresults map'
      'list map' / minmax(0px, 820px) minmax(38vw, 1fr);
  }
  .gridAreaHeading {
    grid-area: heading / heading / heading / heading;
  }
  .gridAreaFilter {
    grid-area: filters / filters / filters / filters;
  }
  .gridAreaNoResults {
    grid-area: noresults / noresults / noresults / noresults;
  }
  .gridAreaList {
    grid-area: list / list / list / list;
  }
  .gridAreaMap {
    grid-area: map / map / map / map;
  }

  .filterChoiceRow {
    @apply flex cursor-pointer items-center;
    > * {
      flex-grow: 0;
    }
  }

  /* Check if u can do it with tailwind */
  .collapsibleHeader {
    transition: padding-bottom 0.2s ease-out;
  }
  .collapsibleContent {
    @apply box-border overflow-hidden md:grid md:w-auto md:grid-cols-[50%_50%];
    transition: height 0.2s ease-out;
  }

  .icon-style {
    --active-color: transparent;
    --hover-color: transparent;
    vertical-align: top;
    min-width: 24px;
  }

  .display-inherit {
    display: inherit;
  }
  .AdtileContextWrapperLs {
    > * :last-child {
      flex: 1;
    }
  }
  .AdtileContextWrapperIm {
    > * :last-child {
      flex: 1;
      @apply mr-l;
    }
    > * :first-child {
      @apply mr-l;
    }
  }

  .description-section-homepage {
    p {
      strong {
        color: #876f45;
        @apply bg-harvest-lighter-90;
      }
      i {
        color: #876f45;
        @apply bg-harvest-lighter-90;
        font-style: normal;
      }
    }
  }

  .dynamic-editor {
    @apply font-b-l break-words;
    > * {
      @apply mb-l;
      @apply md:mb-xl;
    }
    h1 {
      @apply font-t-xl;
    }
    h2 {
      @apply font-t-l;
    }
    h3 {
      @apply font-t-m;
    }
    a {
      @apply text-viridian-base;
      text-decoration: underline;
    }
    ul,
    > ul,
    ol,
    > ol {
      list-style: disc;
      @apply mt-xs;
      @apply mr-0;
      @apply mb-xl;
      padding-inline-start: 1.5rem;
      @apply md:mt-0;
      li {
        @apply mb-xs;
        overflow-x: visible;
      }
    }
    figure {
      padding-inline: 1px;
      @apply md:w-full !important;
    }
    figure > img {
      @apply w-full;
      @apply h-auto;
      @apply mb-l;
      @apply md:mb-xl;
    }
    figure.table {
      table {
        width: 100%;
        max-width: 100vw;
        overflow: auto;
        display: block;
        border-collapse: collapse;
        box-shadow: 0 0 0 1px rgb(231, 232, 234);
        border-radius: 0.25rem;
        border: 1px solid #e7e8ea;
        @apply md:mb-xl;
        & > thead,
        & > tbody {
          & > tr {
            @apply border-b-[1px];
            @apply border-slate-lighter-90;
            & > th,
            & > td {
              @apply py-m;
              @apply px-l;
              text-align: left;
            }
          }
        }
        & > thead > tr {
          @apply bg-slate-lighter-95;
          @apply pr-m;
          th {
            @apply font-t-xs-azo;
          }
        }
        & > tbody > tr {
          &:last-child {
            border-bottom: none;
          }
          td {
            @apply font-b-m;
          }
        }
        & figure.image {
          line-height: normal;
          @apply h-l;
          & > img {
            @apply w-auto;
            @apply h-full;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .dynamic-list-two-columns {
    & * {
      line-height: 2rem;
    }

    &ul,
    &ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-inline-start: 2rem;
      margin: 0.5rem 0 1.25rem;
      @apply md:mt-0;
      & li {
        @apply mb-s;
        width: 100%;
        @apply md:w-[calc(50%-1.5rem)];
      }
    }
  }

  .html-content-v2 {
    a {
      @apply text-viridian-base hover:cursor-pointer hover:underline;
    }
    p {
      @apply mt-6;
    }
    p:first-child {
      @apply !mt-0;
    }
  }

  .html-content {
    a {
      @apply text-viridian-base hover:cursor-pointer hover:underline;
    }
    p {
      @apply mt-2;
    }
  }

  .resource-center-topic-wrapper .selectWrapper .react-select__control {
    @apply !hidden rounded-b-3xl;
  }

  .resource-center-explore-topic-bg-img {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75)
    );
  }

  .resource-center-styled-link {
    & {
      position: relative;

      &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  .resourceCenterHeaderMenu {
    & a {
      @apply font-b-m p-m hover:bg-viridian-lighter-90 flex;
      & :active {
        @apply active:bg-viridian-base text-white;
      }
    }

    & a:last-of-type {
      @apply lg:hidden;
    }
  }

  .resourceCenterHeader {
    @apply cursor-pointer;
    & * {
      @apply flex cursor-pointer items-center;
    }
    & > div,
    & > a {
      @apply py-m px-0 leading-[44px];
      @apply hover:border-viridian-base hover:border-b-4 hover:border-solid hover:pb-3;
    }
  }

  /* Select Component Styles Start*/

  .sly-select-option .react-select__menu-list & .react-select__option {
    @apply text-viridian-base pl-0;
  }

  .selectWrapper {
    .react-select__value-container {
      @apply min-h-[3rem] overflow-visible pr-0;
    }

    hr {
      @apply m-0 p-0;
    }

    .sly-select-option {
      @apply min-h-max;
    }
    .react-select__multi-value {
      @apply bg-slate-lighter-90;
    }
    .react-select__multi-value__label {
      @apply text-[100%];
    }

    .react-select__control {
      @apply border-slate-lighter-90 shadow-none;
      &--menu-is-open {
        @apply border-viridian-base rounded-bl-none rounded-br-none;
      }
    }
    .react-select__control--menu-is-open {
      @apply border-viridian-base rounded-bl-none rounded-br-none;
    }

    .react-select__control--is-disabled {
      @apply bg-slate-lighter-90;
    }

    .react-select__menu-list {
      @apply max-h-max pt-0 pb-0 lg:max-h-[9.5rem];
    }

    .react-select__indicator {
      @apply py-0 px-1;
    }

    .react-select__indicator-separator {
      @apply hidden;
    }
    .react-select__option,
    .react-select__single-value {
      @apply ml-0 flex items-center;
    }

    .react-select__group-heading {
      @apply text-viridian-base font-medium;
    }

    .react-select__menu {
      @apply top-[100%]  min-w-max rounded-tl-none rounded-tr-none;
    }
  }

  .marketing-element-link {
    ul {
      @apply m-0 p-0;
      list-style-type: none;
      li {
        @apply text-slate-base;
        &:not(:last-child) {
          @apply mb-l;
        }
      }
    }
    p {
      @apply text-slate-base;
    }
    a {
      @apply text-viridian-base no-underline;
    }
  }

  .marketing-how-it-works-block-tile {
    p {
      strong {
        @apply font-t-m mb-m block;
      }
    }
  }

  .marketing-faq-element-link {
    p {
      @apply font-b-l md:pr-xxl;
    }
    a {
      @apply text-viridian-base no-underline;
    }
  }
  /* Select Component Styles End*/

  .hero-wrapper {
    @apply relative;
    height: 30rem;
    > * {
      @apply absolute;
      @apply left-0;
      @apply top-0;
      @apply w-full;
      @apply h-full;
    }
  }

  .hero-text-wrapper {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
    @apply items-center;

    > * {
      @apply max-w-[calc(var(--col4))];
      @apply md:max-w-[calc(var(--col6))];
      @apply lg:max-w-[calc(var(--col8))];
    }
  }

  .faq-section-transition {
    transition: padding-bottom 0.2s ease-out;
  }

  .select-disabled-gray {
    color: gray;
    option {
      color: black;
    }
  }

  .agent-legacy-cv {
    h1 {
      color: #70767f;
    }
    p {
      color: #121c2b;
      font-weight: 500;
    }
    margin-bottom: 1.5rem;
    a {
      text-decoration: none;
      @apply text-viridian-base;
      &:hover {
        @apply text-viridian-lighter-20;
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .legal-page-content {
    h1 {
      @apply mb-6 text-[1.75rem] font-medium leading-[1.29] text-slate-800;
    }
    h2 {
      @apply mb-4 text-xl font-medium leading-[1.6] text-slate-800;
    }
    h3 {
      @apply font-t-s-azo my-4 text-xl font-medium leading-[1.6] text-slate-800;
    }
    a {
      @apply text-viridian-base underline;
    }
    p {
      @apply my-2;
    }
    ul {
      @apply list-inside list-disc;
    }
    ul ul {
      @apply list-inside list-[square] pl-10;
    }
  }

  .responsive-image-wrapper {
    display: block;
    position: relative;
    width: 100%;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    img {
      user-select: none;
      border: none;
      object-fit: cover;
    }

    * {
      border-radius: inherit;
    }
  }
}

.formDatePicker .react-datepicker-popper {
  @apply !z-[2000] !border-none !shadow-none;
}

.formDatePicker .react-datepicker__input-container input {
  @apply border-none;
}
.formDatePicker .react-datepicker__input-container input:focus {
  --tw-ring-shadow: none !important;
}

.formDatePicker .react-datepicker__current-month {
  font-size: 0.944rem !important;
}

.formDatePicker .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input {
  @apply form-input block w-full rounded border border-gray-300 bg-white text-base shadow-sm md:text-sm;
}

.react-datepicker-popper {
  @apply z-40 w-72 rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.react-datepicker-right {
  @apply absolute right-0 left-auto top-11 transform-none !important;
}

.react-datepicker__portal {
  @apply absolute top-12 right-0 z-10 w-72 transform-none rounded border-2 border-gray-200 bg-white px-3 py-2 text-sm shadow;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 text-lg font-semibold text-gray-800;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-400;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded py-1 text-sm leading-loose text-gray-700 transition;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-blue-500 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-blue-500 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-viridian-base text-white;
}

.react-datepicker__day--range-start {
  @apply bg-viridian-base text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
  @apply bg-viridian-base text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--highlighted {
  @apply bg-viridian-base text-white;
}

.roko-instabot-widget-button {
  z-index: 10000 !important;
}
.roko-instabot-widget,
.body #accessiblyAppWidgetButton.accessibly-app-widget-button-container {
  z-index: 999 !important;
  background-image: none;
  @apply animate-fadeIn;
}

@media screen and (max-width: 1080px) {
  .roko-instabot-widget-button {
    bottom: 85px !important;
  }
}

@supports (font: -apple-system-body) and (-webkit-appearance: none) {
  img[loading='lazy'] {
    clip-path: inset(0.6px);
  }
}

.content-visibility {
  content-visibility: auto;
  contain-intrinsic-size: auto 2000px;
}

sly-search {
  width: 100%;
}

sly-map {
  height: calc(100vh - 136px);
  @apply md:h-[calc(100vh-68px)];
  display: block;
}

sly-gallery {
  position: fixed;
  display: flex;
  top: 136px;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @apply md:top-[83px];
}

.insights-modal > a:not([hidden]) + a:not([hidden]) {
  margin-top: 8px;
}

.accessibly-otm-widget-button-container {
  z-index: 500 !important;
}

.provider-cta {
  border-radius: var(--radius-m, 8px);
  background: var(--surface-brand-default, #1a7473);
  display: flex;
  padding: var(--spacing-spacing-s, 12px) var(--spacing-spacing-xl, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--spacing-spacing-xs, 8px);
}

.scroll-container {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.provider-gradiant {
  background-image: linear-gradient(274deg, #fff 3.25%, #d1e3e3 96.75%);
}

@media screen(md) {
  .provider-gradiant {
    background-image: linear-gradient(322deg, #fff2 1.03%, #d1e3e3 77.98%);
  }
}

.text-shadow {
  text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.15);
}
